import "./footer.css";

function Footer(){

    return <footer>
        <div className="author">Сайт создан <a href="https://psycholess.com/" target="_blank" className="text_link">Psycholess</a></div>
        <div className="row">
            <div className="text">
                2023 iBurger (Айбургер) Лучегорск <a href="https://www.instagram.com/iburger_luch" target="_blank" className="text_link">Instagram</a>
                <br/>ИП Исаев В. Н., ИНН 252692051333, ОРГНИП 320253600069130
                <br/>Часы работы: ежедневно с 11:00 до 22:00 без перерывов и выходных.
                <br/>Телефон: 8 (924) 008-31-21
                <br/>Адрес: Россия, Приморский край, Пожарский район, пгт Лучегорск, 3-й микрорайон, дом 32 (этаж 1).
                <br/><br/>Изображения на картинках могут отличаться от фактического вида блюд.
                <br/><br/>Остались вопросы? Звоните 8 (924) 008-31-21
            </div>
        </div>
    </footer>
}

export default Footer;