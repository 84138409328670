import './header.css';
import ButtonRegularOrange from "../../components/button-regular-orange/button-regular-orange";
import cartIcon from "./orderbag_icon.png";

import arrowIcon from "./arrow_bottom_icon.png";
import AvatarIcon from "./avatar_icon.svg";
import myOrdersIcon from "./orders_icon.png";
import logoutIcon from "./exit_icon.png";
import nightThemeIcon from "./night_icon.png";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {clearUserAuth} from "../../redux/user/user-state";
import {setOpenedModal, switchAppTheme} from "../../redux/app/app-state";
import ChangeThemeButton from "../../components/change-theme-button/change-theme-button";
import {setCookie} from "../../service-functions/service-functions";
interface HeaderProps {
    handleScrollToUp: ()=> void,
    handleScrollToMenu: ()=> void,
    handleDownloadApp: ()=> void
}

const Header = ({ handleScrollToUp, handleScrollToMenu, handleDownloadApp }: HeaderProps) =>{
    const userState = useSelector((state: RootState) => state.userState);
    const orderTotal = useSelector((state: RootState) => state.userState.orderTotal);
    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();
    const switchTheme = () => {
        alertShowMessage('Вы сменили тему', true);
        dispatch(switchAppTheme());
    }
    const myOrdersHandle = () => dispatch(setOpenedModal({ modalOpened:'myorders' }));
    const authHandle = () => dispatch(setOpenedModal({ modalOpened:'login' }));
    const cartHandle = () => dispatch(setOpenedModal({ modalOpened:'cart' }));

    const logoutHandle = () => {
        dispatch(clearUserAuth());
        alertShowMessage('Вы вышли из аккаунта', true);
        setCookie("authtoken","",-1,"/",true)
    }

    return(
        <div className="header">
            <div>
                <img
                    src="/favicon.ico"
                    className="iblogo"
                    alt="iBurger logo"
                    onClick={handleScrollToUp}
                />
                <div className="iblogo_txt" onClick={handleScrollToUp}>i<span className="color_orange">B</span>urger</div>
                <div className="header_left_nav">
                    <div onClick={handleScrollToMenu}>Меню</div>
                    <div onClick={handleDownloadApp}>Скачать приложение</div>
                </div>
            </div>
            <div>
                {userState.authStatus==false && <>
                    <ChangeThemeButton />
                    <ButtonRegularOrange text="Войти в аккаунт" isLoading={false} isDeactivated={false} callbackFunc={authHandle}/>
                </>}
                {userState.authStatus==true &&
                    <>
                        <div className="header_menu_parent">
                            <button className="header_account_button">
                                <div className="header_account_button_text">{userState.userName}</div>
                                <div className="header_account_button_counter">4</div>
                                <img src={arrowIcon} className="header_account_button_arrow" alt="arrow"/>
                                <img src={AvatarIcon} className="header_account_button_avatar" alt="avatar"/>
                                <div className="header_menu">
                                    <HeaderMenuButton icon={myOrdersIcon} text="Мои заказы" onClick={myOrdersHandle}/>
                                    <HeaderMenuButton icon={nightThemeIcon} text="Ночная тема" onClick={switchTheme}/>
                                    <HeaderMenuButton icon={logoutIcon} text="Выйти" onClick={logoutHandle}/>
                                </div>
                            </button>
                        </div>
                        <button className="header_orderbag_button" onClick={cartHandle}>
                            <img className="header_orderbag_button_img" src={cartIcon} alt="cart"/>
                            <div className="header_orderbag_button_price">{`${orderTotal}₽`}</div>
                        </button>
                    </>
                }
                {userState.authStatus == undefined && <>
                    <ChangeThemeButton />
                    <div className="header_right_skeleton"/>
                </>}
            </div>
        </div>
    );
}
export default Header;


interface PropsHeaderMenuButton {
    icon: string,
    text: string,
    onClick: () => void
}
function HeaderMenuButton({ icon, text, onClick }: PropsHeaderMenuButton) {
    return <button className="header_menu_item" onClick={onClick}>
        <img src={icon} className="header_menu_item_img" alt="icon"/>
        <div className="header_menu_item_text">{text}</div>
    </button>;
}